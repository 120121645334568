<template>
  <div class="steps-body-container">
    <h1 v-if="title" :class="[isDark ? 'title-dark' : 'title-light']">{{ title }}</h1>
    <h3 v-if="subtitle"
        :class="['work-item-subhead', isDark ? 'title-dark' : 'title-light']"
    >{{ subtitle }}</h3>
    <h4 v-if="stepName"
        :class="[isDark ? 'title-dark' : 'title-light']"
    >

      <span class="pre">
        Step
        {{ stepNumber }}:
      </span>
      {{ stepName }}
    </h4>
    <p class="markdown" :style="{color: myColors.foreground}">
      <VueMarkdown :html="useHTML">{{ description }}</VueMarkdown>
    </p>
    <slot/>
  </div>
</template>
<script>
import {store} from "@/store/store.js";
import {colors} from "@/core/colors.js";
import VueMarkdown from "vue-markdown";

export default {
  components: {
    VueMarkdown,
  },
  store: store,
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      default: "Body",
      type: String,
      required: false,
    },
    stepName: {
      type: String,
      required: false,
    },
    stepNumber: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    titleType: {
      default: "spaced-out",
      type: String,
    },
  },

  data() {
    return {
      useHTML: true,
    };
  },

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
};
</script>
<style scoped>
.title-light {
  color: var(--primaryColor-dark);
}

.title-dark {
  color: var(--primaryColor-dark);
}

p > a {
  color: #fa713f;
}

.steps-body-container {
  text-align: left;
  display: flex;
  flex-flow: column;
  width: 52.58rem;
  margin: 0 0 8rem 0;
}

.work-item-subhead {
  margin: 0 0 2rem 0 !important;
}

h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 2rem 0;
}

.pre {
  font-weight: bold;
}

@media (max-width: 480px) {
  .markdown,
  h4 {
    padding: 0 2rem;
  }
}
</style>